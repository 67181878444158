<template>
  <Layout>
    <div class="space-y-20  font-montserrat">
      <div class="row  flex justify-center">
        <div
          class="col-md-10 bg-primary rounded-2xl py-2 text-white text-center"
        >
          <h3 class="text-white">
            {{ invoice.clientFirstName }} {{ invoice.clientLastName }}'s Invoice
            Details( {{ invoice.invoiceNumber }})
          </h3>
          <h4 class="text-white">
            Invoice Details, Benefactors, Documents and Payments in one place
          </h4>
        </div>
      </div>
      <div class="row flex justify-center">
        <div class="col-md-3  mx-2">
          <div class="row">
            <h3>Customer Details</h3>
            <div
              class="col-md-12 py-4 h-60 space-y-4 text-white rounded-2xl px-4 bg-primary"
            >
              <h6 class="text-white">Internal Number : {{ results.clientInternalNumber }}</h6>
            

              <h6 class="text-white">Phone Number: {{ invoice.clientPhoneNumber }}</h6>
              

              <h6 class="text-white">Email Address :</h6>
              <p class="h6 text-white">{{ invoice.clientEmail }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 mx-2">
          <div class="row ">
            <h3>Service Documents</h3>
            <div class="col-md-12  h-60 py-2 bg-warning rounded-2xl px-4 ">
              <div
                v-for="(file, key) in results.documents"
                :key="key"
                class="result bg-primary text-white px-4  rounded-2xl py-1 mt-3"
              >
                <div class="d-flex justify-content-between">
                  <span> {{ file.fullName }}</span>
                  <span class="text-oonpaysecondary"
                    ><a
                      target="_blank"
                      :href="
                        `https://oonpaystorage.blob.core.windows.net/oonpayinvoices/${encodeURIComponent(
                          file.docUrl
                        )}`
                      "
                      ><i class="fa fa-eye" aria-hidden="true"></i></a
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-white mx-2">
          <h3>Bill Details</h3>
          <div class="row">
            <div class="col-md-12 space-y-4 py-2 h-60 rounded-2xl px-4 bg-primary">
              <div>
                <h6 class="text-white">Bill : {{ results.totalCost }}</h6>
               
              </div>
              <div class="flex justify-between">
                <div>
                  <h6 class="text-white">Total Paid</h6>
                  <p class="h6 text-white">GHC {{ results.totalPaid }}</p>
                </div>
                <div>
                  <h6 class="text-white">Balance</h6>
                  <p class="h6 text-white">{{ Math.round(results.totalCost - results.totalPaid) }}</p>
                </div>
              </div>
              <div>
                <h6 class="text-white">Bill Due Date</h6>
                <p class="h6 text-white">
                  {{ serviceEndDate | formatDate("LL") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row flex justify-center">
        <div class="col-md-3  mx-2">
          <div class="row">
            <h3>Service Details</h3>
            <div
              class="col-md-12 py-4 space-y-4 text-white h-60 rounded-2xl px-4 bg-warning"
            >
              <h6 class="text-white">Invoice Date : <b class="h6  text-white">{{
                results.serviceStartDate | formatDate("LL")
              }}</b></h6>
              

              <h6 class="text-white">Service Duration</h6>
              <p class="h6 text-white">
                {{ results.serviceStartDate | formatDate("LL") }} -
                {{ results.serviceEndDate | formatDate("LL") }}
              </p>

              <div>
                Services Offered :
                <div class="ex1">
                  <div
                    v-for="(service, key) in results.servicesOffered"
                    :key="key"
                    class="result  bg-primary text-white px-4  rounded-2xl  mt-1"
                  >
                    <div class="d-flex justify-content-between">
                      <span> {{ service.serviceFullName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mx-2">
          <div class="row">
            <h3>Benefactors Details</h3>
            <div class="col-md-12 rounded-2xl  h-60 bg-primary px-4">
              <div
                v-for="(service, key) in results.benefactors"
                :key="key"
                class="result  bg-oonpaysecondary text-white px-4  rounded-2xl py-1 mt-2"
              >
                <div class="d-flex justify-content-between">
                  <span>
                    {{ service.clientFullName }}
                    {{ service.clientPhoneNumber }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 space-y-2 text-white mx-2">
          <h3>Payment History</h3>
          <div class="row">
            <div class="col-md-12 py-2 rounded-2xl h-60 px-4 bg-primary"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Oonpay",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      invoices: [],
      invoice: {},
      invoice_guid: "",
      results: {},
      serviceEndDate: "",
    };
  },

  methods: {
    getAllInvoices() {
      this.$store.dispatch("invoice/getAllInvoice").then((response) => {
        this.invoices = response.data;
        this.invoices.forEach((invoice) => {
          if (invoice.id == this.$route.params.id) {
            this.invoice = invoice;
            console.log(this.invoice);
            this.totalSumMed = invoice.totalCost;
            this.invoice_guid = invoice.guid;

            this.getInvoiceDetails();
          }
        });
      });
    },
    getInvoiceDetails() {
      this.$store
        .dispatch("invoice/getInvoiceDetails", this.invoice_guid)
        .then((response) => {
          console.log(response);
          this.results = response.data;
          this.serviceEndDate = this.results.serviceEndDate;
        });
    },
    viewDoc(key) {},
  },
  mounted() {
    this.getAllInvoices();
  },
};
</script>

<style>
div.ex1 {
  height: 60px;
  overflow-y: scroll;
}

.bg-primary {
  background: rgb(3, 36, 77) !important;
}

 .bg-warning {
  background: #f15825 !important;
}

.fa-eye{
  color: #f15825;
}
</style>
